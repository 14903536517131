import { formatLocaleDate } from '@globals';
import { DocumentManifest, DocumentReference } from '@hl7fhir';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import { DocumentManifestViewModel } from '../document-manifest';
import { DocumentReferenceViewModel } from '../document-reference';

export class DocumentViewViewModel extends DomainResourceViewModel<DocumentReference | DocumentManifest> {
  isLast: boolean;
  private showDetailMaster = false;
  private masterDocument: DocumentViewViewModel | undefined;

  constructor(
    document: DocumentReference | DocumentManifest,
    documentView: DocumentViewViewModel | undefined = undefined,
    isLast = false,
  ) {
    super(document);
    this.masterDocument = documentView;
    this.isLast = isLast;
  }

  get master(): DocumentViewViewModel | undefined {
    return this.masterDocument;
  }

  get documentReferenceViewModel(): DocumentReferenceViewModel | undefined {
    const documentReference = this.resource as DocumentReference;
    if (documentReference.resourceType === 'DocumentReference') {
      return new DocumentReferenceViewModel(documentReference);
    }

    return undefined;
  }

  get documentManifestViewModel(): DocumentManifestViewModel | undefined {
    const documentManifest = this.resource as DocumentManifest;
    if (documentManifest.resourceType === 'DocumentManifest') {
      return new DocumentManifestViewModel(documentManifest);
    }

    return undefined;
  }

  get dateTime(): string | undefined {
    if (this.resource?.resourceType === 'DocumentReference') {
      const documentReference = this.resource as r3.DocumentReference;
      return documentReference?.indexed && formatLocaleDate(documentReference.indexed);
    } else if (this.resource?.resourceType === 'DocumentManifest') {
      const documentManifest = this.resource as DocumentManifest;
      return documentManifest?.created && formatLocaleDate(documentManifest.created);
    }

    return undefined;
  }

  get date(): string | undefined {
    if (this.resource?.resourceType === 'DocumentReference') {
      const documentReference = this.resource as r3.DocumentReference;
      return documentReference?.indexed && formatLocaleDate(documentReference.indexed.split('T')[0]);
    } else if (this.resource?.resourceType === 'DocumentManifest') {
      const documentManifest = this.resource as DocumentManifest;
      return documentManifest?.created && formatLocaleDate(documentManifest.created.split('T')[0]);
    }

    return undefined;
  }

  get dateWithoutLocale(): string | undefined {
    if (this.resource?.resourceType === 'DocumentReference') {
      const documentReference = this.resource as r3.DocumentReference;
      return documentReference?.indexed;
    } else if (this.resource?.resourceType === 'DocumentManifest') {
      const documentManifest = this.resource as DocumentManifest;
      return documentManifest?.created;
    }

    return undefined;
  }

  get title(): string | undefined {
    const documentReference = this.resource as DocumentReference;
    if (documentReference?.resourceType === 'DocumentReference') {
      // FHIR NL only has one content
      return documentReference.content?.[0]?.attachment.title ?? documentReference?.description ?? '-';
    } else if (this.resource?.resourceType === 'DocumentManifest') {
      return this.documentManifestViewModel?.type + ' ' + this.documentManifestViewModel?.subject;
    }

    return undefined;
  }

  get count(): string | undefined {
    const documentManifest = this.resource as DocumentManifest;

    if (documentManifest.resourceType === 'DocumentManifest') {
      return documentManifest?.content?.length.toString();
    }

    return undefined;
  }

  get status(): string | undefined {
    if (this.documentReferenceViewModel) {
      return this.documentReferenceViewModel?.status;
    } else if (this.documentManifestViewModel) {
      return this.documentManifestViewModel?.status;
    }

    return undefined;
  }

  get type(): string | undefined {
    if (this.documentReferenceViewModel) {
      return this.documentReferenceViewModel?.type;
    } else if (this.documentManifestViewModel) {
      return this.documentManifestViewModel?.type;
    }

    return undefined;
  }

  get class(): string | undefined {
    if (this.documentReferenceViewModel) {
      return this.documentReferenceViewModel?.class;
    }

    return undefined;
  }

  get subject(): string | undefined {
    if (this.documentReferenceViewModel) {
      return this.documentReferenceViewModel?.subject;
    } else if (this.documentManifestViewModel) {
      return this.documentManifestViewModel?.subject;
    }

    return undefined;
  }

  get author(): string | undefined {
    if (this.documentReferenceViewModel) {
      return this.documentReferenceViewModel?.author;
    } else if (this.documentManifestViewModel) {
      return this.documentManifestViewModel?.author;
    }

    return undefined;
  }

  get fileLocation(): string | undefined {
    if (this.documentReferenceViewModel) {
      return this.documentReferenceViewModel?.fileLocation;
    }

    return undefined;
  }

  get securityLabel(): string | undefined {
    if (this.documentReferenceViewModel) {
      return this.documentReferenceViewModel?.securityLabel;
    }

    return undefined;
  }

  get isShowDetailMaster(): boolean {
    return this.showDetailMaster;
  }

  set isShowDetailMaster(value: boolean) {
    this.showDetailMaster = value;
  }
}
