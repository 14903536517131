import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { Extension, Flag } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel, PeriodTypePipe } from '@hl7fhir/data-types';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { getReference } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { FlagStatusPipe } from '@hl7fhir/value-sets';

export class FlagViewModel extends DomainResourceViewModel<Flag> {
  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.code);
  }

  get status(): string | undefined {
    return new FlagStatusPipe().transform(this.resource?.status);
  }

  get subject(): string | undefined {
    return getReference(this.resource?.subject);
  }

  get encounter(): string | undefined {
    return getReference(this.resource?.encounter);
  }

  get category(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.category);
  }

  get author(): string | undefined {
    return getReference(this.resource?.author);
  }

  get period(): string | undefined {
    return new PeriodTypePipe().transform(this.resource?.period);
  }

  get priority(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Hl7.FLAG.priority,
    );

    return extension?.valueCodeableConcept
      ? new CodeableConceptPipe().transform(extension?.valueCodeableConcept)
      : undefined;
  }

  get detail(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Hl7.FLAG.detail,
    );

    return extension?.valueReference ? getReference(extension?.valueReference) : undefined;
  }
}
