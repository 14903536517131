import { formatLocaleDate } from '@globals';
import { Immunization } from '@hl7fhir';
import {
  AnnotationViewModel,
  CodeableConceptPipe,
  getChoiceOfType,
  getSourceChoiceOfType,
  IdentifierViewModel,
  YesNoPipe,
} from '@hl7fhir/data-types';
import { getReference, getReferences, VersionPipe } from '@hl7fhir/foundation';
import { ImmunizationStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { ImmunizationEducationViewModel } from './immunization-education.viewmodel';
import { ImmunizationExplanationViewModel } from './immunization-explanation.viewmodel';
import { ImmunizationPractitionerViewModel } from './immunization-practitioners.viewmodel';
import { ImmunizationProgramEligibilityViewModel } from './immunization-program-eligibility.viewmodel';
import { ImmunizationReactionViewModel } from './immunization-reaction.viewmodel';
import { ImmunizationVaccinationProtocolViewModel } from './immunization-vaccination-protocol.viewmodel';

export class ImmunizationViewModel extends DomainResourceViewModel<Immunization> {
  get identifier(): IdentifierViewModel[] | undefined {
    return (
      this.resource?.identifier &&
      this.resource.identifier.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion))
    );
  }

  get status(): string | undefined {
    return this.resource?.status && new ImmunizationStatusPipe().transform(this.resource.status);
  }

  //Renamed to "statusReason" in R4, R4b, and R5
  get notGiven(): string | undefined {
    const immuneR3 = this.resource as r3.Immunization;
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    if (this.fhirVersion === 'R3') {
      return new YesNoPipe().transform(immuneR3.notGiven);
    }

    if (['R4', 'R4b', 'R5'].includes(this.fhirVersion ?? '')) {
      return new CodeableConceptPipe().transform(immuneR.statusReason);
    }
    return undefined;
  }

  get vaccineCode(): string | undefined {
    return this.resource?.vaccineCode && new CodeableConceptPipe().transform(this.resource.vaccineCode);
  }

  get patient(): string | undefined {
    return this.resource?.patient && getReference(this.resource.patient);
  }

  get encounter(): string | undefined {
    return this.resource?.encounter && getReference(this.resource.encounter);
  }

  //Turned into "occurence[x]" in R4, R4b, and R5
  get occurence(): string | undefined {
    const immuneR3 = this.resource as r3.Immunization;
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    if (this.fhirVersion === 'R3') {
      return immuneR3.date && formatLocaleDate(immuneR3.date);
    }

    if (['R4', 'R4b', 'R5'].includes(this.fhirVersion ?? '')) {
      return getChoiceOfType({ dateTime: immuneR.occurrenceDateTime, string: immuneR.occurrenceString });
    }
    return undefined;
  }

  get sortDate(): string | undefined {
    const immuneR3 = this.resource as r3.Immunization;
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    if (this.fhirVersion === 'R3') {
      return immuneR3.date;
    }

    if (['R4', 'R4b', 'R5'].includes(this.fhirVersion ?? '')) {
      return getSourceChoiceOfType({ dateTime: immuneR.occurrenceDateTime, string: immuneR.occurrenceString });
    }
    return undefined;
  }

  get primarySource(): string | undefined {
    if (!this.resource?.primarySource) {
      return undefined;
    }

    return new YesNoPipe().transform(this.resource.primarySource);
  }

  //R3, R4, R4b only
  get reportOrigin(): string | undefined {
    const immune = this.resource as r3.Immunization | r4.Immunization | r4b.Immunization;
    return immune.reportOrigin && new CodeableConceptPipe().transform(immune.reportOrigin);
  }

  get location(): string | undefined {
    return this.resource?.location && getReference(this.resource.location);
  }

  //Turned into CodeableReference in R5
  get manufacturer(): string | undefined {
    const immuneR = this.resource as r3.Immunization | r4.Immunization | r4.Immunization;
    const immuneR5 = this.resource as r5.Immunization;

    return (
      (immuneR.manufacturer && getReference(immuneR.manufacturer)) ??
      (immuneR5.manufacturer && getReference(immuneR5.manufacturer?.reference))
    );
  }

  get lotNumber(): string | undefined {
    return this.resource?.lotNumber;
  }

  get expirationDate(): string | undefined {
    return this.resource?.expirationDate && formatLocaleDate(this.resource.expirationDate);
  }

  get site(): string | undefined {
    return this.resource?.site && new CodeableConceptPipe().transform(this.resource.site);
  }

  get route(): string | undefined {
    return this.resource?.route && new CodeableConceptPipe().transform(this.resource.route);
  }

  get doseQuantity(): string | undefined {
    return this.resource?.doseQuantity?.value?.toString();
  }

  //Renamed to "performer" in R4, R4b, and R5
  get practitioner(): ImmunizationPractitionerViewModel[] | undefined {
    const immuneR3 = this.resource as r3.Immunization;
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    return (
      (immuneR3.practitioner &&
        immuneR3.practitioner.map((item) => new ImmunizationPractitionerViewModel(item, this.fhirVersion))) ??
      (immuneR.performer &&
        immuneR.performer.map((item) => new ImmunizationPractitionerViewModel(item, this.fhirVersion)))
    );
  }

  get annotations(): AnnotationViewModel[] | undefined {
    return (
      this.resource?.note &&
      this.resource.note.map((annotation) => new AnnotationViewModel(annotation, this.fhirVersion))
    );
  }

  //R3 only
  get explanation(): ImmunizationExplanationViewModel | undefined {
    const immuneR3 = this.resource as r3.Immunization;
    return immuneR3.explanation && new ImmunizationExplanationViewModel(immuneR3.explanation, this.fhirVersion);
  }

  get reaction(): ImmunizationReactionViewModel[] | undefined {
    return (
      this.resource?.reaction &&
      this.resource.reaction.map((item) => new ImmunizationReactionViewModel(item, this.fhirVersion))
    );
  }

  //Renamed to "protocolApplied" in R4, R4b, and R5
  get vaccinationProtocol(): ImmunizationVaccinationProtocolViewModel[] | undefined {
    const immuneR3 = this.resource as r3.Immunization;
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    return (
      (immuneR3.vaccinationProtocol &&
        immuneR3.vaccinationProtocol.map(
          (item) => new ImmunizationVaccinationProtocolViewModel(item, this.fhirVersion),
        )) ??
      (immuneR.protocolApplied &&
        immuneR.protocolApplied.map((item) => new ImmunizationVaccinationProtocolViewModel(item, this.fhirVersion)))
    );
  }

  //R4, R4b only
  get education(): ImmunizationEducationViewModel[] | undefined {
    const immuneR4 = this.resource as r4.Immunization | r4b.Immunization;

    return (
      immuneR4.education && immuneR4.education.map((item) => new ImmunizationEducationViewModel(item, this.fhirVersion))
    );
  }

  //R4, R4b, R5 only
  get fundingSource(): string | undefined {
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    return immuneR.fundingSource && new CodeableConceptPipe().transform(immuneR.fundingSource);
  }

  //R4, R4b, R5 only, turned from CodableConcept into a BackboneElement in R5
  get programEligibility(): string | undefined {
    const isR = new VersionPipe().transform(this.resource, 'R4', 'R4b');
    if (isR) {
      const immuneR = this.resource as r4.Immunization | r4b.Immunization;
      return (
        immuneR.programEligibility &&
        immuneR.programEligibility.map((item) => new CodeableConceptPipe().transform(item)).join(',')
      );
    }

    const isR5 = new VersionPipe().transform(this.resource, 'R5');
    if (isR5) {
      const immuneR5 = this.resource as r5.Immunization;

      return (
        immuneR5.programEligibility &&
        immuneR5.programEligibility
          .map((item) => {
            const pe = new ImmunizationProgramEligibilityViewModel(item, this.fhirVersion);
            return (
              new CodeableConceptPipe().transform(pe.element?.program) +
              ' - ' +
              new CodeableConceptPipe().transform(pe.element?.programStatus)
            );
          })
          .join(',')
      );
    }

    return undefined;
  }

  //R4, R4b, R5 only
  get subpotentReason(): string | undefined {
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    return immuneR.subpotentReason && new CodeableConceptPipe().transform(immuneR.subpotentReason);
  }

  //R4, R4b, R5 only
  get isSubpotent(): string | undefined {
    const immuneR = this.resource as r4.Immunization | r4b.Immunization | r5.Immunization;

    if (immuneR.isSubpotent) {
      return undefined;
    }

    return new YesNoPipe().transform(immuneR.isSubpotent);
  }

  //R4, R4b only
  get reasonReference(): string | undefined {
    const immuneR = this.resource as r4.Immunization | r4b.Immunization;

    return immuneR.reasonReference && getReferences(immuneR.reasonReference);
  }

  //R4, R4b only
  get recorded(): string | undefined {
    const immuneR = this.resource as r4.Immunization | r4b.Immunization;

    return immuneR.recorded;
  }

  //R4, R4b only
  get reasonCode(): string | undefined {
    const immuneR = this.resource as r4.Immunization | r4b.Immunization;

    return immuneR.reasonCode && immuneR.reasonCode.map((item) => new CodeableConceptPipe().transform(item)).join(',');
  }

  //R5 only
  get reason(): string | undefined {
    const immuneR5 = this.resource as r5.Immunization;

    return immuneR5.reason && getReferences(immuneR5.reason.map((item) => item.reference!));
  }

  //R5 only
  get informationSource(): string | undefined {
    const immuneR5 = this.resource as r5.Immunization;

    return immuneR5.informationSource && getReference(immuneR5.informationSource.reference);
  }

  //R5 only
  get supportingInformation(): string | undefined {
    const immuneR5 = this.resource as r5.Immunization;

    return immuneR5.supportingInformation && getReferences(immuneR5.supportingInformation);
  }

  //R5 only
  get administeredProduct(): string | undefined {
    const immuneR5 = this.resource as r5.Immunization;

    return immuneR5.administeredProduct && new CodeableConceptPipe().transform(immuneR5.administeredProduct);
  }

  //R5 only
  get basedOn(): string | undefined {
    const immuneR5 = this.resource as r5.Immunization;

    return immuneR5.basedOn && getReferences(immuneR5.basedOn);
  }
}
