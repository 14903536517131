import { Coding, Quantity } from '@hl7fhir';
import {
  COD016_VEKT,
  GBATabel32Nationaliteiten,
  GBATabel34Landen,
  ISO3166_1Alpha2,
  RoleCodeNLZorgverlenertypen,
  UZOVI as UZOVI_CODE_SYSTEMS,
} from '@hl7nl-fhir/codesystems';
import { AvailableLocales, toLocale } from '@language';
import { AddressType } from './address-type.codesystem';
import { AddressUse } from './address-use.codesystem';
import { ConsentActionCodes } from './consent-action-codes.codesystem';
import { ConsentDataMeaning } from './consent-data-meaning.codesystem';
import { ConsentExceptType } from './consent-except-type.codesystem';
import { DICOMControlledTerminologyDefinitions } from './dicom-controlled-terminology-definitions.codesystem';
import { FlagCategory } from './flag-category.codesystem';
import { FlagPriorityCodes } from './flag-priority-codes.codesystem';
import { FlagStatus } from './flag-status.codesystem';
import { CodeSystemFlattened } from './models/code-system-flattened.model';
import { OrganizationTypeCodeSystem } from './organization-type.codesystem';
import { ResourceType } from './resource-type.codesystem';
import { SecurityRoleType } from './security-role-type.codesystem';
import { UnitOfMeasureCodes } from './unit-of-measure.codesystem';
import { v3CodeSystemActCode } from './v3-code-system-act-code.codesystem';
import { v3CodeSystemActReason } from './v3-code-system-act-reason.codesystem';
import { v3CodeSystemActUSPrivacyLaw } from './v3-code-system-act-us-privacy-law.codesystem';
import { v3CodeSystemConfidentiality } from './v3-code-system-confidentiality.codesystem';
import { v3CodeSystemLanguageAbilityMode } from './v3-code-system-language-ability-mode.codesystem';
import { v3CodeSystemLanguageAbilityProficiency } from './v3-code-system-language-ability-proficiency.codesystem';
import { v3CodeSystemObservationValue } from './v3-code-system-observation-value.codesystem';
import { v3CodeSystemParticipationFunction } from './v3-code-system-participation-function.codesystem';
import { v3CodeSystemParticipationType } from './v3-code-system-participation-type.codesystem';
import { v3CodeSystemRoleClass } from './v3-code-system-role-class.codesystem';
import { v3CodeSystemRoleCode } from './v3-code-system-role-code.codesystem';
import { v3CodeSystemTimingEvent } from './v3-code-system-timing-event.codesystem';

export const ICPC_1_NL = 'http://hl7.org/fhir/sid/icpc-1-nl';

// SOURCES
export const HL7 = 'http://terminology.hl7.org/CodeSystem';
export const SNOMED = 'http://snomed.info/sct';
export const LOINC = 'http://loinc.org';
export const NHG_45 = 'https://referentiemodel.nhg.org/tabellen/nhg-tabel-45-diagnostische-bepalingen';
export const UZOVI = 'http://fhir.nl/fhir/NamingSystem/uzovi';

// SYSTEMS
export const CONTACT_ENTITY_TYPE = `${HL7}/contactentity-type`;
export const ALLERGY_INTOLERANCE_CLINICAL = `${HL7}/allergyintolerance-clinical`;
export const ALLERGY_INTOLERANCE_VERIFICATION = `${HL7}/allergyintolerance-verification`;
export const CONDITION_CLINICAL = `${HL7}/condition-clinical`;
export const V2_PROVIDER_ROLE = `${HL7}/v2-0286`;
export const V3_MARITAL_STATUS = `${HL7}/v3-MaritalStatus`;
export const V3_NULL_FLAVOR = `${HL7}/v3-NullFlavor`;
export const ADDRESS_USE = 'http://hl7.org/fhir/address-use';
export const ADDRESS_TYPE = 'http://hl7.org/fhir/address-type';
export const ORGANIZATION_TYPE = `${HL7}/organization-type`;
export const PATIENT_CONTACT_ROLE_CODE = 'urn:oid:2.16.840.1.113883.2.4.3.11.22.472';
export const HOME_ACCESSIBILITY_CODELIST_CODE = 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.2.7.8.3';
export const HOME_ADAPTION_CODELIST_CODE = 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.2.7.8.2';
export const TYPE_OF_HOME_CODELIST_CODE = 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.2.7.8.1';
export const WONING_TYPE_CODELIJST_CODE = 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.13.1';

export const DIGI_ME = 'urn:oid:2.16.840.1.113883.2.4.3.220';

// R3
export const HL7_R3 = 'http://hl7.org/fhir/v3';
export const R3_V3_RELATED_CODE = `${HL7_R3}/RoleCode`;
export const R3_V3_MARITAL_STATUS = `${HL7_R3}/MaritalStatus`;
export const R3_V3_NULL_FLAVOR = `${HL7_R3}/NullFlavor`;

interface CodeSystemIndexEntry {
  label?: string;
  system?: CodeSystemFlattened;
}

const systems: Record<string, CodeSystemIndexEntry> = {
  agb: { label: 'AGB' },
  atc: { label: 'ATC' },
  big: { label: 'BIG' },
  bsn: { label: 'BSN' },
  gpk: { label: 'GPK' },
  gtin: { label: 'GTIN' },
  hpk: { label: 'HPK' },
  prk: { label: 'PRK' },
  ura: { label: 'URA' },
  uzi: { label: 'UZI' },
  uzovi: { label: 'UZOVI', system: UZOVI_CODE_SYSTEMS },
  zi: { label: 'ZI' },

  // FHIR
  addressUse: { system: AddressUse },
  addressType: { system: AddressType },
  organizationType: { system: OrganizationTypeCodeSystem },
  flagCategory: { system: FlagCategory },
  flagPriorityCodes: { system: FlagPriorityCodes },
  flagStatus: { system: FlagStatus },
  v3CodeSystemLanguageAbilityProficiency: { system: v3CodeSystemLanguageAbilityProficiency },
  v3CodeSystemLanguageAbilityMode: { system: v3CodeSystemLanguageAbilityMode },
  // NL
  iso31661Alpha2: { system: ISO3166_1Alpha2 },
  gbaTabel34Landen: { system: GBATabel34Landen },
  gbaTabel32Nationaliteiten: { system: GBATabel32Nationaliteiten },
  roleCodeNLZorgverlenertypen: { system: RoleCodeNLZorgverlenertypen },
  cod016Vekt: { system: COD016_VEKT },
  // Treatment Directive
  v3CodeSystemActCode: { system: v3CodeSystemActCode },
  v3CodeSystemActReason: { system: v3CodeSystemActReason },
  v3CodeSystemActUSPrivacyLaw: { system: v3CodeSystemActUSPrivacyLaw },
  v3CodeSystemConfidentiality: { system: v3CodeSystemConfidentiality },
  v3CodeSystemObservationValue: { system: v3CodeSystemObservationValue },
  consentExceptType: { system: ConsentExceptType },
  resourceType: { system: ResourceType },
  consentDataMeaning: { system: ConsentDataMeaning },
  v3CodeSystemRoleClass: { system: v3CodeSystemRoleClass },
  v3CodeSystemRoleCode: { system: v3CodeSystemRoleCode },
  v3CodeSystemParticipationFunction: { system: v3CodeSystemParticipationFunction },
  v3CodeSystemParticipationType: { system: v3CodeSystemParticipationType },
  securityRoleType: { system: SecurityRoleType },
  dicomControlledTerminologyDefinitions: { system: DICOMControlledTerminologyDefinitions },
  consentActionCodes: { system: ConsentActionCodes },
  v3CodeSystemTimingEvent: { system: v3CodeSystemTimingEvent },

  unitOfMeasure: { system: UnitOfMeasureCodes },
};

/**
 * We get identifier labels either from HL7 NL OID index in combination with the profiles to get the url based variant.
 */
const index: Record<string, CodeSystemIndexEntry> = {
  // AGB
  'http://fhir.nl/fhir/NamingSystem/agb-z': systems['agb'],
  'urn:oid:2.16.840.1.113883.2.4.6.1': systems['agb'],

  // BIG
  'http://fhir.nl/fhir/NamingSystem/big': systems['big'],
  'urn:oid:2.16.528.1.1007.5.1': systems['big'],

  // BSN
  'urn:oid:2.16.528.1.1007.4.1': systems['bsn'],
  'http://fhir.nl/fhir/NamingSystem/bsn': systems['bsn'],

  // URA
  'http://fhir.nl/fhir/NamingSystem/ura': systems['ura'],
  'urn:oid:2.16.528.1.1007.3.3': systems['ura'],

  // UZOVI
  'http://fhir.nl/fhir/NamingSystem/uzovi': systems['uzovi'],
  'urn:oid:2.16.840.1.113883.2.4.6.4': systems['uzovi'],

  // UZI
  'http://fhir.nl/fhir/NamingSystem/uzi-nr-pers': systems['uzi'],
  'urn:oid:2.16.528.1.1007.3.1': systems['uzi'],

  // Z-Index
  'http://fhir.nl/fhir/NamingSystem/g-standard-A': systems['zi'],
  'urn:oid:2.16.840.1.113883.2.4.4.8': systems['zi'],

  // HPK
  'http://fhir.nl/fhir/NamingSystem/g-standard-TC': systems['hpk'],
  'urn:oid:2.16.840.1.113883.2.4.4.7': systems['hpk'],

  // GTIN
  'http://fhir.nl/fhir/NamingSystem/gtin': systems['gtin'],
  'urn:oid:1.3.160': systems['gtin'],

  // GPK
  'http://fhir.nl/fhir/NamingSystem/g-standard-GPC': systems['gpk'],
  'urn:oid:2.16.840.1.113883.2.4.4.1': systems['gpk'],

  // PRK
  'http://fhir.nl/fhir/NamingSystem/g-standard-PP': systems['prk'],
  'urn:oid:2.16.840.1.113883.2.4.4.10': systems['prk'],

  'http://www.whocc.no/atc': systems['atc'],

  // HL7 FHIR
  'http://hl7.org/fhir/organization-type': systems['organizationType'],

  // NL
  'urn:iso:std:iso:3166': systems['iso31661Alpha2'],
  'urn:oid:2.16.840.1.113883.2.4.4.16.34': systems['gbaTabel34Landen'],
  'urn:oid:2.16.840.1.113883.2.4.4.16.32': systems['gbaTabel32Nationaliteiten'],

  // LanguageAbility
  'http://terminology.hl7.org/CodeSystem/v3-LanguageAbilityMode': systems['v3CodeSystemLanguageAbilityMode'],
  'http://terminology.hl7.org/CodeSystem/v3-LanguageAbilityProficiency':
    systems['v3CodeSystemLanguageAbilityProficiency'],

  // NL: uzi role code
  'http://fhir.nl/fhir/NamingSystem/uzi-rolcode': systems['roleCodeNLZorgverlenertypen'],
  'urn:oid:2.16.840.1.113883.2.4.15.111': systems['roleCodeNLZorgverlenertypen'],

  // NL: COD016_VEKT
  'urn:oid:2.16.840.1.113883.2.4.6.7': systems['cod016Vekt'],

  // flag
  'http://hl7.org/fhir/flag-category': systems['flagCategory'],
  'urn:oid:2.16.840.1.113883.4.642.1.113': systems['flagCategory'],
  'http://hl7.org/fhir/flag-priority-code': systems['flagPriorityCodes'],
  'urn:oid:2.16.840.1.113883.4.642.1.868': systems['flagPriorityCodes'],
  'http://hl7.org/fhir/flag-status': systems['flagStatus'],
  'urn:oid:2.16.840.1.113883.4.642.1.111': systems['flagStatus'],

  'http://hl7.org/fhir/address-use': systems['addressUse'],
  'http://hl7.org/fhir/address-type': systems['addressType'],
  // Treatment Directive
  'http://hl7.org/fhir/v3/ActCode': systems['v3CodeSystemActCode'],
  'urn:oid:2.16.840.1.113883.5.4': systems['v3CodeSystemActCode'],
  'http://hl7.org/fhir/v3/ActReason': systems['v3CodeSystemActReason'],
  'urn:oid:2.16.840.1.113883.5.8': systems['v3CodeSystemActReason'],
  'http://hl7.org/fhir/v3/ActUSPrivacyLaw': systems['v3CodeSystemActUSPrivacyLaw'],
  'urn:oid:2.16.840.1.113883.5.1138': systems['v3CodeSystemActUSPrivacyLaw'],
  'http://hl7.org/fhir/v3/Confidentiality': systems['v3CodeSystemConfidentiality'],
  'urn:oid:2.16.840.1.113883.5.25': systems['v3CodeSystemConfidentiality'],
  'http://hl7.org/fhir/v3/ObservationValue': systems['v3CodeSystemObservationValue'],
  'urn:oid:2.16.840.1.113883.5.1063': systems['v3CodeSystemObservationValue'],
  'http://hl7.org/fhir/consent-except-type': systems['consentExceptType'],
  'urn:oid:2.16.840.1.113883.4.642.1.742': systems['consentExceptType'],
  'http://hl7.org/fhir/resource-types': systems['resourceType'],
  'http://hl7.org/fhir/consent-data-meaning': systems['consentDataMeaning'],
  'urn:oid:2.16.840.1.113883.4.642.1.744': systems['consentDataMeaning'],
  'http://hl7.org/fhir/v3/RoleClass': systems['v3CodeSystemRoleClass'],
  'urn:oid:2.16.840.1.113883.5.110': systems['v3CodeSystemRoleClass'],
  'http://hl7.org/fhir/v3/RoleCode': systems['v3CodeSystemRoleCode'],
  'urn:oid:2.16.840.1.113883.5.111': systems['v3CodeSystemRoleCode'],
  'http://hl7.org/fhir/v3/ParticipationFunction': systems['v3CodeSystemParticipationFunction'],
  'urn:oid:2.16.840.1.113883.5.88': systems['v3CodeSystemParticipationFunction'],
  'http://hl7.org/fhir/v3/ParticipationType': systems['v3CodeSystemParticipationType'],
  'urn:oid:2.16.840.1.113883.5.90': systems['v3CodeSystemParticipationType'],
  'http://hl7.org/fhir/extra-security-role-type': systems['securityRoleType'],
  'http://dicom.nema.org/resources/ontology/DCM': systems['dicomControlledTerminologyDefinitions'],
  'http://hl7.org/fhir/consentaction': systems['consentActionCodes'],
  'urn:oid:2.16.840.1.113883.4.642.1.738': systems['consentActionCodes'],
  'http://hl7.org/fhir/v3/TimingEvent': systems['v3CodeSystemTimingEvent'],

  'http://unitsofmeasure.org': systems['unitOfMeasure'],
};

/**
 * Returns the label of the CodeSystem for the given system
 * @param system The code system the lookup
 * @returns the label of the code system
 */
export function getLabel(system: string | undefined): string | undefined {
  if (!system) {
    return undefined;
  }

  return index[system]?.label;
}

export function getInfoUrl(system: string | undefined, code: string | undefined): string | undefined {
  if (!system || !code) {
    return undefined;
  }

  if (system === 'ATC') {
    return `https://atcddd.fhi.no/atc_ddd_index/?code=${code}`;
  }

  return undefined;
}

export function getDisplay(coding: Coding) {
  if (!coding.system || !coding.code) {
    return undefined;
  }

  const entry = index[coding.system]?.system?.codes[coding.code];
  if (entry !== undefined) {
    const preferredLanguage: AvailableLocales = toLocale($localize.locale);
    // Select the preferred locale otherwise fallback to the display.
    return entry[preferredLanguage] ?? entry.display;
  }

  return undefined;
}

export function getDisplayForQuantity(quantity: Quantity) {
  if (!quantity.system || !quantity.code) {
    return undefined;
  }

  const entry = index[quantity.system]?.system?.codes[quantity.code];
  if (entry !== undefined) {
    const preferredLanguage: AvailableLocales = toLocale($localize.locale);
    // Select the preferred locale otherwise fallback to the display.
    return entry[preferredLanguage] ?? entry.display;
  }

  return undefined;
}
