import { formatLocaleDate } from '@globals';
import { Appointment, Extension } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel, PeriodTypePipe } from '@hl7fhir/data-types';
import { ExtensionPipe, ExtensionsPipe } from '@hl7fhir/extensibility';
import { getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { AppointmentStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { AppointmentOnlineEditableViewModel } from './appointment-online-editable.viewmodel';
import { AppointmentParticipantViewModel } from './appointment-participant.viewmodel';

export class AppointmentViewModel extends DomainResourceViewModel<Appointment> {
  get identifiers(): IdentifierViewModel[] | undefined {
    return (
      this.resource?.identifier &&
      this.resource.identifier.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion))
    );
  }

  get appointmentType(): string | undefined {
    return this.resource?.appointmentType && new CodeableConceptPipe().transform(this.resource.appointmentType);
  }

  get description(): string | undefined {
    return this.resource?.description;
  }

  get start(): string | undefined {
    return this.resource?.start && formatLocaleDate(this.resource.start);
  }

  get sortDateStart(): string | undefined {
    return this.resource?.start;
  }

  get end(): string | undefined {
    return this.resource?.end && formatLocaleDate(this.resource.end);
  }

  get sortDateEnd(): string | undefined {
    return this.resource?.end;
  }

  get participants(): AppointmentParticipantViewModel[] | undefined {
    return (
      this.resource?.participant &&
      this.resource.participant.map((participant) => new AppointmentParticipantViewModel(participant, this.fhirVersion))
    );
  }

  get status(): string | undefined {
    return this.resource?.status && new AppointmentStatusPipe().transform(this.resource.status);
  }

  //R3, R4, R4b only
  get comment(): string | undefined {
    const appointmentR = this.resource as r3.Appointment | r4.Appointment | r4b.Appointment;
    return appointmentR?.comment;
  }

  get created(): string | undefined {
    return this.resource?.created;
  }

  //R3 only
  get incomingReferral(): string | undefined {
    const appointmentR3 = this.resource as r3.Appointment;
    return appointmentR3?.incomingReferral && getReferences(appointmentR3.incomingReferral);
  }

  //R3 only
  get indication(): string | undefined {
    const appointmentR3 = this.resource as r3.Appointment;
    return appointmentR3?.indication && new CodeableConceptPipe().transform(appointmentR3.indication);
  }

  get minutesDuration(): string | undefined {
    return this.resource?.minutesDuration?.toString();
  }

  get priority(): string | undefined {
    return this.resource?.priority?.toString();
  }

  //R3, R4, R4b only
  get reason(): string | undefined {
    const isR3 = this?.fhirVersion === 'R3';
    const isR4OrR4b = this?.fhirVersion === 'R4' || this?.fhirVersion === 'R4b';
    if (isR3) {
      const appointmentR3 = this.resource as r3.Appointment;
      return appointmentR3?.reason && new CodeableConceptPipe().transform(appointmentR3.reason);
    } else if (isR4OrR4b) {
      const appointmentR4R4b = this.resource as r4.Appointment | r4b.Appointment;
      return appointmentR4R4b?.reasonCode && new CodeableConceptPipe().transform(appointmentR4R4b.reasonCode);
    }

    return undefined;
  }

  get requestedPeriod(): string | undefined {
    return this.resource?.requestedPeriod && new PeriodTypePipe().transform(this.resource.requestedPeriod);
  }

  get serviceCategory(): string | undefined {
    return this.resource?.serviceCategory && new CodeableConceptPipe().transform(this.resource.serviceCategory);
  }

  get serviceType(): string | undefined {
    return this.resource?.serviceType && new CodeableConceptPipe().transform(this.resource.serviceType);
  }

  get slot(): string | undefined {
    return this.resource?.slot && getReferences(this.resource.slot);
  }

  get specialty(): string | undefined {
    return this.resource?.specialty && new CodeableConceptPipe().transform(this.resource.specialty);
  }

  get supportingInformation(): string | undefined {
    return this.resource?.supportingInformation && getReferences(this.resource.supportingInformation);
  }

  get patientInstructions(): string | undefined {
    if (this.resource?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.resource.extension,
        StructureDefinition.Nictiz.APPOINTMENT.patientInstructions,
      );
      return extension?.valueString;
    }

    return undefined;
  }

  get onlineEditable(): AppointmentOnlineEditableViewModel[] | undefined {
    if (this.resource?.extension) {
      const extensions: Extension[] | undefined = new ExtensionsPipe().transform(
        this.resource.extension,
        StructureDefinition.Nictiz.APPOINTMENT.onlineEditable,
      );

      if (extensions) {
        return extensions.map((e) => new AppointmentOnlineEditableViewModel(e, this.fhirVersion));
      }
    }

    return undefined;
  }

  get orderStatus(): string | undefined {
    if (this.resource?._status?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.resource._status.extension,
        StructureDefinition.Nictiz.CODE.specification,
      );

      return extension?.valueCodeableConcept && new CodeableConceptPipe().transform(extension.valueCodeableConcept);
    }

    return undefined;
  }
}
