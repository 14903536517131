import {
  AccountsResponse,
  AuthorizationDetails,
  CAFileListResponse,
  PortabilityReport,
  ReadAccountsResponse,
  UserAD,
  UserResponse,
} from '@digi.me/models';
import { createReducer, on } from '@ngrx/store';
import { AddingState, ErrorState, LoadingState, Result } from '@store';
import { DIGI_ME_FHIR_ACTIONS } from '../hl7fhir/fhir.actions';
import { OBSERVATION_UI_ACTIONS, SHARE_OBSERVATION_ACTIONS } from '../hl7fhir/observation';
import {
  AUTHORIZE_URL_API_ACTIONS,
  CANCEL_SIGN_UP_API_ACTIONS,
  CLAIM_API_ACTIONS,
  CLEAR_UI_DATA_ACTIONS,
  DELETE_API_ACTIONS,
  DELETE_UI_ACTIONS,
  FILES_API_ACTIONS,
  FILE_LIST_API_ACTIONS,
  FORCE_UPDATE_API_ACTIONS,
  PORTABILITY_REPORT_API_ACTIONS,
  READ_ACCOUNTS_API_ACTIONS,
  RESET_API_ACTIONS,
  RESET_UI_ACTIONS,
  SIGN_UP_API_ACTIONS,
  STORAGE_FILE_API_ACTIONS,
  Trigger,
  UPDATE_AD_USER_API_ACTIONS,
  USER_AD_API_ACTIONS,
  USER_API_ACTIONS,
} from './digi-me.actions';

export interface DigiMeState {
  accounts: Result<ReadAccountsResponse>;
  onboarding: Result<AuthorizationDetails>;
  files: {
    callState: LoadingState | ErrorState;
    trigger: Trigger;
    loaded: string[];
  };
  fileList: Result<CAFileListResponse>;
  reset: {
    callState: LoadingState | ErrorState;
    showWarning: boolean;
  };
  delete: {
    callState: LoadingState | ErrorState;
    showWarning: boolean;
  };
  claim: {
    toClaim: string | undefined;
    callState: LoadingState | ErrorState;
  };
  portabilityReport: Result<PortabilityReport>;
  user: Result<UserResponse>;
  userAd: Result<UserAD>;
  share: {
    account: AccountsResponse | undefined;
    data: string | undefined;
  };
  storage: {
    addingState: AddingState;
  };
  selfMeasure: {
    deleting: boolean;
  };
  forceUpdate: {
    force: {
      show: boolean;
    };
    notification: {
      show: boolean;
    };
    warning: {
      show: boolean;
    };
    updateUrl: string;
    callState: LoadingState | ErrorState;
  };
}

export const initialState: DigiMeState = {
  accounts: {
    data: null,
    callState: LoadingState.init,
  },
  onboarding: {
    data: null,
    callState: LoadingState.init,
  },
  files: {
    callState: LoadingState.init,
    trigger: undefined,
    loaded: [],
  },
  fileList: {
    data: null,
    callState: LoadingState.init,
  },
  reset: {
    callState: LoadingState.init,
    showWarning: false,
  },
  delete: {
    callState: LoadingState.init,
    showWarning: false,
  },
  claim: {
    toClaim: undefined,
    callState: LoadingState.init,
  },
  portabilityReport: {
    data: null,
    callState: LoadingState.init,
  },
  user: {
    data: null,
    callState: LoadingState.init,
  },
  userAd: {
    data: null,
    callState: LoadingState.init,
  },
  share: {
    account: undefined,
    data: localStorage.getItem('digi-me-measurements-bundle')
      ? JSON.parse(localStorage.getItem('digi-me-measurements-bundle')!)
      : undefined,
  },
  storage: {
    addingState: AddingState.init,
  },
  selfMeasure: {
    deleting: false,
  },
  forceUpdate: {
    force: {
      show: false,
    },
    notification: {
      show: false,
    },
    warning: {
      show: false,
    },
    updateUrl: '',
    callState: LoadingState.init,
  },
};

export const reducer = createReducer(
  initialState,
  // FILE LIST API
  on(FILE_LIST_API_ACTIONS.fileListRequested, (state): DigiMeState => {
    return {
      ...state,
      fileList: {
        data: null,
        callState: LoadingState.loading,
      },
    };
  }),
  on(FILE_LIST_API_ACTIONS.fileListLoadSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      fileList: {
        data: action.fileList,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(FILE_LIST_API_ACTIONS.fileListLoadFailed, (state, action): DigiMeState => {
    return {
      ...state,
      fileList: {
        ...state.fileList,
        callState: { error: action.error },
      },
    };
  }),

  // FORCE UPDATE
  on(FORCE_UPDATE_API_ACTIONS.forceUpdateRequested, (state): DigiMeState => {
    return {
      ...state,
      forceUpdate: {
        force: { show: false },
        notification: { show: false },
        warning: { show: false },
        updateUrl: '',
        callState: LoadingState.loading,
      },
    };
  }),
  on(FORCE_UPDATE_API_ACTIONS.forceUpdateSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      forceUpdate: {
        force: action.mobileVersionDetails.force,
        notification: action.mobileVersionDetails.notification,
        warning: action.mobileVersionDetails.warning,
        updateUrl: action.mobileVersionDetails.updateUrl ?? '',
        callState: LoadingState.loaded,
      },
    };
  }),
  on(FORCE_UPDATE_API_ACTIONS.forceUpdateFailed, (state, action): DigiMeState => {
    return {
      ...state,
      forceUpdate: {
        force: state.forceUpdate.force,
        notification: state.forceUpdate.notification,
        warning: state.forceUpdate.warning,
        updateUrl: state.forceUpdate.updateUrl,
        callState: { error: action.error },
      },
    };
  }),

  // AUTHORIZE URL SERVICES API
  on(AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested, (state): DigiMeState => {
    return {
      ...state,
      onboarding: {
        data: null,
        callState: LoadingState.loading,
      },
    };
  }),
  on(AUTHORIZE_URL_API_ACTIONS.authorizeUrlLoadSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      onboarding: {
        data: action.url,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(AUTHORIZE_URL_API_ACTIONS.authorizeUrlLoadFailed, (state, action): DigiMeState => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        callState: { error: action.error },
      },
    };
  }),

  // UPDATE AD USER API
  on(UPDATE_AD_USER_API_ACTIONS.updateADUserRequested, (state): DigiMeState => {
    return {
      ...state,
      userAd: {
        ...state.userAd,
        callState: LoadingState.loading,
      },
    };
  }),
  on(UPDATE_AD_USER_API_ACTIONS.updateADUserSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      userAd: {
        data: action,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(UPDATE_AD_USER_API_ACTIONS.updateADUserFailed, (state, action): DigiMeState => {
    return {
      ...state,
      userAd: {
        ...state.userAd,
        callState: { error: action.error },
      },
    };
  }),

  // GET AD USER API
  on(USER_AD_API_ACTIONS.userADRequested, (state): DigiMeState => {
    return {
      ...state,
      userAd: {
        data: null,
        callState: LoadingState.loading,
      },
    };
  }),
  on(USER_AD_API_ACTIONS.userADSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      userAd: {
        data: action,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(USER_AD_API_ACTIONS.userADFailed, (state, action): DigiMeState => {
    return {
      ...state,
      userAd: {
        ...state.userAd,
        callState: { error: action.error },
      },
    };
  }),

  // READ ACCOUNTS API
  on(READ_ACCOUNTS_API_ACTIONS.accountsLoadSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      accounts: {
        data: action.accounts,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(READ_ACCOUNTS_API_ACTIONS.accountsLoadFailed, (state, action): DigiMeState => {
    return {
      ...state,
      accounts: {
        ...state.accounts,
        callState: { error: action.error },
      },
    };
  }),
  // SHARE UI
  on(SHARE_OBSERVATION_ACTIONS.findAccountSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      share: {
        account: action.account,
        data: state.share.data,
      },
    };
  }),
  on(SHARE_OBSERVATION_ACTIONS.reset, (state): DigiMeState => {
    return {
      ...state,
      share: initialState.share,
    };
  }),
  // FILES SERVICES API
  on(FILES_API_ACTIONS.filesRequested, (state, action): DigiMeState => {
    return {
      ...state,
      files: {
        callState: action.sourceFetch ? LoadingState.background : LoadingState.loading,
        trigger: action.trigger,
        loaded: state.files.loaded,
      },
    };
  }),
  on(FILES_API_ACTIONS.filesLoadSucceeded, (state): DigiMeState => {
    return {
      ...state,
      files: {
        callState: LoadingState.loaded,
        trigger: undefined,
        loaded: state.files.loaded,
      },
    };
  }),
  on(FILES_API_ACTIONS.filesLoadFailed, (state, action): DigiMeState => {
    return {
      ...state,
      files: {
        callState: { error: action.error },
        trigger: undefined,
        loaded: state.files.loaded,
      },
    };
  }),
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, action): DigiMeState => {
    return {
      ...state,
      files: {
        ...state.files,
        loaded: [...state.files.loaded, action.fileName],
      },
    };
  }),
  // RESET UI
  on(RESET_UI_ACTIONS.resetWarningRequested, (state): DigiMeState => {
    return {
      ...state,
      reset: {
        ...state.reset,
        showWarning: true,
      },
    };
  }),
  on(RESET_UI_ACTIONS.resetWarningDismissed, (state): DigiMeState => {
    return {
      ...state,
      reset: {
        ...state.reset,
        showWarning: false,
      },
    };
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (): DigiMeState => {
    return {
      ...initialState,
    };
  }),
  // RESET API
  on(RESET_API_ACTIONS.resetRequested, (state): DigiMeState => {
    return {
      ...state,
      reset: {
        showWarning: false,
        callState: LoadingState.loading,
      },
    };
  }),
  on(RESET_API_ACTIONS.resetSucceeded, (state): DigiMeState => {
    return {
      ...state,
      reset: {
        showWarning: false,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(RESET_API_ACTIONS.resetFailed, (state, action): DigiMeState => {
    return {
      ...state,
      reset: {
        showWarning: false,
        callState: { error: action.error },
      },
    };
  }),
  // DELETE UI
  on(DELETE_UI_ACTIONS.deleteWarningRequested, (state): DigiMeState => {
    return {
      ...state,
      delete: {
        ...state.reset,
        showWarning: true,
      },
    };
  }),
  on(DELETE_UI_ACTIONS.deleteWarningDismissed, (state): DigiMeState => {
    return {
      ...state,
      delete: {
        ...state.reset,
        showWarning: false,
      },
    };
  }),
  // DELETE API
  on(DELETE_API_ACTIONS.deleteRequested, (state): DigiMeState => {
    return {
      ...state,
      delete: {
        showWarning: false,
        callState: LoadingState.loading,
      },
    };
  }),
  on(DELETE_API_ACTIONS.deleteSucceeded, (state): DigiMeState => {
    return {
      ...state,
      delete: {
        showWarning: false,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(DELETE_API_ACTIONS.deleteFailed, (state, action): DigiMeState => {
    return {
      ...state,
      delete: {
        showWarning: false,
        callState: { error: action.error },
      },
    };
  }),
  // PORTABILITY REPORT API
  on(PORTABILITY_REPORT_API_ACTIONS.portabilityReportRequested, (state): DigiMeState => {
    return {
      ...state,
      portabilityReport: {
        data: null,
        callState: LoadingState.loading,
      },
    };
  }),
  on(PORTABILITY_REPORT_API_ACTIONS.portabilityReportLoadSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      portabilityReport: {
        data: action.report,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(PORTABILITY_REPORT_API_ACTIONS.portabilityReportLoadFailed, (state, action): DigiMeState => {
    return {
      ...state,
      portabilityReport: {
        ...state.portabilityReport,
        callState: { error: action.error },
      },
    };
  }),
  // SIGN UP
  on(SIGN_UP_API_ACTIONS.signUpAsked, (state, action): DigiMeState => {
    return {
      ...state,
      claim: {
        toClaim: action.library,
        callState: state.claim.callState,
      },
    };
  }),
  // CLAIM API
  on(CLAIM_API_ACTIONS.claimRequested, (state): DigiMeState => {
    return {
      ...state,
      claim: {
        toClaim: state.claim.toClaim,
        callState: LoadingState.loading,
      },
    };
  }),
  on(CLAIM_API_ACTIONS.claimLoadSucceeded, (state): DigiMeState => {
    return {
      ...state,
      claim: {
        toClaim: undefined,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(CLAIM_API_ACTIONS.claimLoadFailed, (state, action): DigiMeState => {
    return {
      ...state,
      claim: {
        toClaim: undefined,
        callState: { error: action.error },
      },
    };
  }),
  // CANCEL SIGN UP
  on(CANCEL_SIGN_UP_API_ACTIONS.cancelSignUpStarted, (state): DigiMeState => {
    return {
      ...state,
      claim: {
        toClaim: undefined,
        callState: state.claim.callState,
      },
    };
  }),
  // USER API
  on(USER_API_ACTIONS.userDataLoadSucceeded, (state, action): DigiMeState => {
    return {
      ...state,
      user: {
        data: action.user,
        callState: LoadingState.loaded,
      },
    };
  }),
  on(USER_API_ACTIONS.userDataLoadFailed, (state, action): DigiMeState => {
    return {
      ...state,
      user: {
        data: null,
        callState: { error: action.error },
      },
    };
  }),
  on(STORAGE_FILE_API_ACTIONS.addStorageFile, (state): DigiMeState => {
    return {
      ...state,
      storage: {
        addingState: AddingState.adding,
      },
    };
  }),
  on(STORAGE_FILE_API_ACTIONS.addStorageFileSucceeded, (state): DigiMeState => {
    return {
      ...state,
      storage: {
        addingState: AddingState.added,
      },
    };
  }),
  on(STORAGE_FILE_API_ACTIONS.addStorageFileFailed, (state): DigiMeState => {
    return {
      ...state,
      storage: {
        addingState: AddingState.error,
      },
    };
  }),
  on(STORAGE_FILE_API_ACTIONS.resetStorageState, (state): DigiMeState => {
    return {
      ...state,
      storage: {
        addingState: AddingState.init,
      },
    };
  }),
  on(OBSERVATION_UI_ACTIONS.remove, (state): DigiMeState => {
    return {
      ...state,
      selfMeasure: {
        deleting: true,
      },
    };
  }),
  on(OBSERVATION_UI_ACTIONS.removeSuccess, (state): DigiMeState => {
    return {
      ...state,
      selfMeasure: {
        deleting: false,
      },
    };
  }),
);
