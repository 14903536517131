import { formatLocaleDate } from '@globals';
import { MedicationDispense } from '@hl7fhir';
import {
  AnnotationViewModel,
  CodeableConceptPipe,
  getChoiceOfType,
  IdentifierViewModel,
  QuantityTypePipe,
  YesNoPipe,
} from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { MedicationDispenseStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import { DosageViewModel } from '../dosage';
import { MedicationDispensePerformerViewModel } from './medication-dispense-performer.viewmodel';
import { MedicationDispenseSubstitutionViewModel } from './medication-dispense-substitution.viewmodel';

export class MedicationDispenseViewModel extends DomainResourceViewModel<MedicationDispense> {
  get identifier() {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get authorizingPrescription() {
    return getReferences(this.resource?.authorizingPrescription);
  }

  get category() {
    return new CodeableConceptPipe().transform(this.resource?.category);
  }

  get context() {
    return getReference(this.resource?.context);
  }

  get daysSupply() {
    return new QuantityTypePipe().transform(this.resource?.daysSupply);
  }

  get destination() {
    return getReference(this.resource?.destination);
  }

  get dosageInstruction() {
    return this.resource?.dosageInstruction?.map((dosage) => new DosageViewModel(dosage, this.fhirVersion));
  }

  get medication() {
    return getChoiceOfType({
      codeableConcept: this.resource?.medicationCodeableConcept,
      reference: this.resource?.medicationReference,
    });
  }

  get status() {
    return new MedicationDispenseStatusPipe().transform(this.resource?.status);
  }

  get subject() {
    return getReference(this.resource?.subject);
  }

  get whenHandedOver() {
    return formatLocaleDate(this.resource?.whenHandedOver);
  }

  get sortDate() {
    return this.resource?.whenHandedOver;
  }

  get whenPrepared() {
    return formatLocaleDate(this.resource?.whenPrepared);
  }

  get notDone() {
    const resourceR3 = this.resource as r3.MedicationDispense;
    return new YesNoPipe().transform(resourceR3.notDone);
  }

  get notDoneReason() {
    const resourceR3 = this.resource as r3.MedicationDispense;
    return getChoiceOfType({
      codeableConcept: resourceR3.notDoneReasonCodeableConcept,
      reference: resourceR3.notDoneReasonReference,
    });
  }

  get note() {
    return this.resource?.note?.map((note) => new AnnotationViewModel(note, this.fhirVersion));
  }

  get partOf() {
    return getReferences(this.resource?.partOf);
  }

  get performer() {
    return this.resource?.performer?.map(
      (performer) => new MedicationDispensePerformerViewModel(performer, this.fhirVersion),
    );
  }

  get quantity() {
    return new QuantityTypePipe().transform(this.resource?.quantity);
  }

  get receiver() {
    return getReferences(this.resource?.receiver);
  }

  get substitution() {
    return new MedicationDispenseSubstitutionViewModel(this.resource?.substitution, this.fhirVersion);
  }

  get type() {
    return new CodeableConceptPipe().transform(this.resource?.type);
  }
}
